<template>
  <v-main class="px-10">
    <v-row align="center">
      <h3 class="ml-md-15 black--text">{{ $t("news") }}</h3>
      <router-link
        class="link-blue mt-2 ml-2 subtitle-1"
        :to="{ name: 'auth1.sign-in1' }"
      >
        {{ $t("seeMore") }}
      </router-link>
    </v-row>
    <v-row>
      <v-col class="mt-4 p-0">
        <v-slide-group>
          <v-slide-item v-for="n in 8" :key="n">
            <v-card class="ma-4 rounded" width="250">
              <v-card-title class="p-0">
                <img
                  src="https://mdbootstrap.com/img/new/standard/nature/187.jpg"
                  class="card-img-top"
                  alt="..."
                />
              </v-card-title>
              <v-card-text>
                <h4 class="mt-3 font-weight-bold">Lorem ipsum</h4>
                <p class="mt-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Parturient tristique sollicitudin massa sit risus iaculis
                  enim. Et pretium viverra sed tempor cum in rhoncus, donec.
                </p>
              </v-card-text>
            </v-card>
          </v-slide-item>
        </v-slide-group>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
export default {
  name: 'News'
};
</script>
