<template>
  <v-main>
    <NavBar />
    <v-col cols="12" class="my-5 p-0 text-center">
      <img
        src="../../assets/images/error/error-01.png"
        alt="chat-user"
        class="w-50"
      />
    </v-col>
    <v-col
      offset-md="5"
      md="2"
      offset="3"
      cols="6"
      class="my-5 p-0 text-center"
    >
      <v-btn
        @click="backHome"
        block
        class="login-btn btn-login-customizer mb-2 white--text"
        color="#59D79D"
      >
        {{ $t("goHome") }}
      </v-btn>
    </v-col>
    <div class="col-12 my-5 p-0">
      <CategoriesCarousel />
    </div>
    <div class="offset-1 col-10 my-3 p-0">
      <News />
    </div>
    <Footer />
  </v-main>
</template>
<script>
import NavBar from '@/components/socialvue/navbars/NavBar';
import Footer from '@/components/socialvue/footer/Footer';
import CategoriesCarousel from '@/components/socialvue/carousel/CategoriesCarousel';
import News from '@/views/Pages/News';

export default {
  name: '404Page',
  components: { NavBar, Footer, CategoriesCarousel, News },
  methods: {
    backHome () {
      let signup = '/';
      if (this.redirect) {
        signup += '?redirect=' + this.redirect;
      }
      this.$router.push(signup);
    }
  }
};
</script>
